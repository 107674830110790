<template>
  <div class="main">

    <div class="note">开通财务功能，资金有迹可循，采购资金直接打款供应商，税务安全合规，简化财务对账！</div>

    <el-steps :active="yeePayMerchant.step" class="steps" align-center>
      <el-step title="提交资料" icon="el-icon-edit-outline"></el-step>
      <el-step title="支付平台审核" icon="el-icon-document-checked"></el-step>
      <el-step title="微信支付审核" icon="el-icon-document-checked"></el-step>
      <el-step title="绑定提现卡" icon="el-icon-circle-check"></el-step>
    </el-steps>

    <div style="display: block; margin-bottom: 70px; clear: both"></div>

    <div v-if="yeePayMerchant.step == 0">
      <div style="width: 60%">
        <el-form
          ref="yeePayMerchant"
          :model="yeePayMerchant"
          :rules="rules"
          size="mini"
          label-width="45%"
        >
          <div
            style="margin: 30px auto; width: 400px"
            v-if="yeePayMerchant.description"
          >
            <el-tag type="danger">{{ yeePayMerchant.description }}</el-tag>
          </div>
          <el-form-item label="组织类型" prop="type">
            <el-radio-group v-model="yeePayMerchant.type" size="mini">
              <el-radio-button
                v-for="(item, index) in typeOptions"
                :key="index"
                :label="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <div v-show="yeePayMerchant.type == 1">
            <el-form-item label="品牌名称">
              <el-input
                v-model="
                  yeePayMerchant.microMerchant.merchantSubjectInfo.shortName
                "
                placeholder="支付收银台页面向买家展示品牌名称"
                show-word-limit
                clearable
                :style="{ width: '100%' }"
              >
              </el-input>
              <label class="tips">在支付收银台页面向买家展示品牌名称</label>
            </el-form-item>
            <el-form-item label="商户证件类型">
              <el-radio-group
                v-model="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceType
                "
                size="medium"
                :disabled="true"
              >
                <el-radio
                  v-for="(
                    item, index
                  ) in yeeMerchantCorporationInfo.legalLicenceTypeOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上传法人身份证人像面" required>
              <el-upload
                class="avatar-uploader"
                ref="yeeMerchantCorporationInfoLegalLicenceFrontUrl"
                :file-list="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceFrontUrlfileList
                "
                action="string"
                :show-file-list="false"
                :before-upload="
                  yeemerchantCorporationInfoLegalLicenceFrontUrlBeforeUpload
                "
                :http-request="uploadIdCardCopy"
                accept="image/*"
              >
                <img
                  v-if="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceFrontPath
                  "
                  :src="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceFrontPath
                  "
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="上传法人身份证国徽面" required>
              <el-upload
                class="avatar-uploader"
                ref="yeeMerchantCorporationInfoLegalLicenceBackUrl"
                :file-list="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceBackUrlfileList
                "
                action="string"
                :before-upload="
                  yeemerchantCorporationInfoLegalLicenceBackUrlBeforeUpload
                "
                :show-file-list="false"
                :http-request="uploadIdCardNational"
                accept="image/*"
              >
                <img
                  v-if="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceBackPath
                  "
                  :src="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceBackPath
                  "
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input
                v-model="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceNo
                "
                placeholder="系统自动识别身份证号"                
                clearable
                :style="{ width: '100%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="身份证名称">
              <el-input
                v-model="
                  yeePayMerchant.microMerchant.merchantSubjectInfo.signName
                "
                placeholder="系统自动识别身份证名称"                
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="身份证有效期开始时间" required>
              <el-input
                v-model="
                  yeePayMerchant.wechatMerchant.identificationValidDateStart
                "
                placeholder="身份证有效期开始时间，格式：1970-01-01 或者 长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="身份证有效期截止时间" required>
              <el-input
                v-model="
                  yeePayMerchant.wechatMerchant.identificationValidDateEnd
                "
                placeholder="身份证有效期截止时间，格式：1970-01-01 或者 长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="商户手机号" required>
              <el-input
                v-model="
                  yeePayMerchant.microMerchant.merchantCorporationInfo.mobile
                "
                placeholder="请输入商户手机号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="提现银行卡号" required>
              <el-input
                v-model="yeePayMerchant.microMerchant.accountInfo.bankCardNo"
                placeholder="请输入提现银行卡号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="银行账户类型"
              prop="accountInfoBankAccountType"
            >
              <el-radio-group
                v-model="
                  yeePayMerchant.microMerchant.accountInfo.bankAccountType
                "
                size="medium"
                :disabled="true"
              >
                <el-radio
                  v-for="(item, index) in accountInfo.bankAccountTypeOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>

            <el-form-item label="开户行名称" required>
              <el-select
                v-model="yeePayMerchant.microMerchant.accountInfo.bankName"
                placeholder="请选择开户行，可输入关键词搜索"
                filterable
                clearable
                @change="selectBank"
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="item in banks"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="系统自动识别开户行编码" required>
              <el-input
                v-model="yeePayMerchant.microMerchant.accountInfo.bankCode"
                placeholder="系统自动识别开户行编码"
                readonly
                :disabled="true"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="经营所在地" required>
              <el-cascader
                style="width: 300px"
                size="mini"
                :options="options"
                v-model="yeePayMerchant.selectedOptions"
                @change="handleChangeCity"
              >
              </el-cascader>
              <el-input
                v-model="
                  yeePayMerchant.microMerchant.businessAddressInfo.address
                "
                size="mini"
                style="margin-top: 10px"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="上传门店门头照" required>
              <el-upload
                id="wechatMerchantHeaderUrl"
                ref="wechatMerchantHeaderUrl"
                :file-list="wechatMerchantHeadFiles"
                :multiple="false"
                action="string"
                :before-upload="
                  companyMerchantSubjectInfoLicenceUrlBeforeUpload
                "
                accept="image/*"
                :http-request="uploadHead"
                :on-change="uploadOnChange"
              >
                <el-button
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item> -->

            <!-- <el-form-item label="上传门店店内照" required>
              <el-upload
                id="wechatMerchantIndoorUrl"
                ref="wechatMerchantIndoorUrl"
                :multiple="false"
                :file-list="wechatMerchantIndoorFiles"
                action="string"
                :before-upload="
                  companyMerchantSubjectInfoLicenceUrlBeforeUpload
                "
                accept="image/*"
                :http-request="uploadIndoor"
                :on-change="uploadOnChange"
              >
                <el-button
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item> -->
          </div>

          <div v-show="yeePayMerchant.type == 2">
            <el-form-item label="品牌名称" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantSubjectInfo.shortName
                "
                placeholder="支付收银台页面向买家展示品牌名称，提交保存后不可修改"
                show-word-limit
                clearable
                :style="{ width: '100%' }"
              >
              </el-input>
              <label class="tips">在支付收银台页面向买家展示品牌名称</label>
            </el-form-item>
            <el-form-item label="组织机构" required>
              <el-select
                v-model="
                  yeePayMerchant.companyMerchant.merchantSubjectInfo.signType
                "
                placeholder="请选择组织机构类型"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(
                    item, index
                  ) in companyMerchantSubjectInfo.signTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上传营业执照" required>
              <el-upload
                id="companyMerchantSubjectInfoLicenceUrl"
                ref="companyMerchantSubjectInfoLicenceUrl"
                :file-list="companyMerchantSubjectInfo.licenceUrlfileList"
                action="string"
                :before-upload="
                  companyMerchantSubjectInfoLicenceUrlBeforeUpload
                "
                accept="image/*"
                :http-request="uploadBusinessPaper"
                :on-change="uploadOnChange"
              >
                <el-button
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="营业执照主体名称" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantSubjectInfo.signName
                "
                placeholder="请输入营业执照主体名称"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantSubjectInfo.licenceNo
                "
                placeholder="输入统一社会信用代码或营业执照编码，例如：92310116071167481H"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="营业执照证件开始时间" required>
              <el-input
                v-model="yeePayMerchant.wechatMerchant.licenceValidDateStart"
                placeholder="格式：1970-01-01 或者 长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="营业执照证件结束时间" required>
              <el-input
                v-model="yeePayMerchant.wechatMerchant.licenceValidDateEnd"
                placeholder="格式：1970-01-01 或者 长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="开户许可证或基本存款账户照片" required>
              <el-upload
                id="companyMerchantSubjectInfoOpenAccountLicenceUrl"
                ref="companyMerchantSubjectInfoOpenAccountLicenceUrl"
                :file-list="
                  companyMerchantSubjectInfo.openAccountLicenceUrlfileList
                "
                action="string"
                :before-upload="
                  companyMerchantSubjectInfoOpenAccountLicenceUrlBeforeUpload
                "
                accept="image/*"
                :on-change="uploadOnChange"
                :http-request="uploadBusinessBank"
              >
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="开户许可证或基本存款账户编号" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantSubjectInfo
                    .openAccountLicenceNo
                "
                placeholder="请输入开户许可证或基本存款账户编号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人证件类型" required>
              <el-radio-group
                v-model="
                  yeePayMerchant.companyMerchant.merchantCorporationInfo
                    .legalLicenceType
                "
                size="medium"
                :disabled="true"
              >
                <el-radio
                  v-for="(
                    item, index
                  ) in merchantCorporationInfo.legalLicenceTypeOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上传法人身份证人像面" required>
              <el-upload
                class="avatar-uploader"
                ref="yeeMerchantCorporationInfoLegalLicenceFrontUrl"
                :file-list="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceFrontUrlfileList
                "
                action="string"
                :show-file-list="false"
                :before-upload="
                  yeemerchantCorporationInfoLegalLicenceFrontUrlBeforeUpload
                "
                :http-request="uploadIdCardCopy"
                accept="image/*"
              >
                <img
                  v-if="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceFrontPath
                  "
                  :src="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceFrontPath
                  "
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="上传法人身份证国徽面" required>
              <el-upload
                class="avatar-uploader"
                ref="yeeMerchantCorporationInfoLegalLicenceBackUrl"
                :file-list="
                  yeePayMerchant.microMerchant.merchantCorporationInfo
                    .legalLicenceBackUrlfileList
                "
                action="string"
                :before-upload="
                  yeemerchantCorporationInfoLegalLicenceBackUrlBeforeUpload
                "
                :show-file-list="false"
                :http-request="uploadIdCardNational"
                accept="image/*"
              >
                <img
                  v-if="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceBackPath
                  "
                  :src="
                    yeePayMerchant.microMerchant.merchantCorporationInfo
                      .legalLicenceBackPath
                  "
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="法人身份证名称" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantCorporationInfo
                    .legalName
                "
                placeholder="系统自动识别法人身份证名称"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="法人身份证号" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantCorporationInfo
                    .legalLicenceNo
                "
                placeholder="系统自动识别法人身份证号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="法人身份证有效期开始时间" required>
              <el-input
                v-model="
                  yeePayMerchant.wechatMerchant.identificationValidDateStart
                "
                placeholder="格式：1970-01-01，如果长期有效请输入：长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="法人身份证有效期截止时间" required>
              <el-input
                v-model="
                  yeePayMerchant.wechatMerchant.identificationValidDateEnd
                "
                placeholder="格式：1970-01-01，如果长期有效请输入：长期"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>

            <el-form-item label="法人手机号" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantCorporationInfo.mobile
                "
                placeholder="请输入法人手机号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务联系人姓名" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantContactInfo.contactName
                "
                placeholder="请输入业务联系人姓名"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务联系人手机" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantContactInfo
                    .contactMobile
                "
                placeholder="请输入业务联系人手机"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务联系人身份证号码" required>
              <el-input
                v-model="yeePayMerchant.wechatMerchant.applicantIdCard"
                placeholder="业务联系人身份证号码"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务联系人邮箱" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.merchantContactInfo
                    .contactEmail
                "
                placeholder="请输入业务联系人邮箱"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="结算方式" required>
              <el-radio-group
                v-model="
                  yeePayMerchant.companyMerchant.settlementAccountInfo
                    .settlementDirection
                "
                size="mini"
                :disabled="true"
              >
                <el-radio
                  v-for="(
                    item, index
                  ) in settlementAccountInfo.settlementDirectionOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="银行账户类型" required>
              <el-select
                v-model="
                  yeePayMerchant.companyMerchant.settlementAccountInfo
                    .bankAccountType
                "
                placeholder="请选择银行账户类型"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(
                    item, index
                  ) in settlementAccountInfo.bankAccountTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行卡号" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.settlementAccountInfo
                    .bankCardNo
                "
                placeholder="请输入银行卡号"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行名称" required>
              <el-select
                v-model="
                  yeePayMerchant.companyMerchant.settlementAccountInfo.bankName
                "
                placeholder="请选择开户行名称，可输入关键词搜索"
                filterable
                clearable
                :style="{ width: '100%' }"
                @change="
                  (val) =>
                    (yeePayMerchant.companyMerchant.settlementAccountInfo.bankCode =
                      val)
                "
              >
                <el-option
                  v-for="item in banks"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="系统自动识别开户行编码" required>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.settlementAccountInfo.bankCode
                "
                placeholder="系统自动识别开户行编码"
                readonly
                :disabled="true"
                clearable
                :style="{ width: '100%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司地址" required>
              <el-cascader
                style="width: 300px"
                size="mini"
                :options="options"
                v-model="yeePayMerchant.selectedOptions"
                @change="handleChangeCity"
              >
              </el-cascader>
              <el-input
                v-model="
                  yeePayMerchant.companyMerchant.businessAddressInfo.address
                "
                size="mini"
                style="margin-top: 10px"
                placeholder="请输入详细地址"
              ></el-input>
            </el-form-item>

            <el-form-item label="上传供应商合作协议资料" required>
              <el-upload
                id="companyMerchantBiz"
                ref="companyMerchantBiz"
                :file-list="companyMerchantBiz"
                :multiple="false"
                action="string"
                :http-request="uploadBiz"
                :on-change="uploadOnChange"
              >
                <el-button
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
              <label style="font-size:12px;color:red;">双方签署的合作合同或协议，需加盖公章</label>
            </el-form-item>

            <el-form-item label="上传业务系统截图" required>
              <el-upload
                id="companyMerchantScreenshot"
                ref="companyMerchantScreenshot"
                :multiple="false"
                :file-list="companyMerchantScreenshot"
                action="string"
                accept="image/*"
                :http-request="uploadScreenshot"
                :on-change="uploadOnChange"
              >
                <el-button
                  size="mini"
                  plain
                  type="primary"
                  icon="el-icon-upload"
                  >点击上传</el-button
                >
              </el-upload>
              <label style="font-size:12px;color:red;">请截取您的【账单管理】页面的截图后上传</label>
            </el-form-item>
          </div>
        </el-form>

        <div style="margin: 50px 0px 20px 200px">
          <el-button plain @click="saveIt">仅保存</el-button>

          <el-button type="primary" :loading="submitLoading" @click="submitForm"
            >提交进入下一步</el-button
          >
        </div>
      </div>
    </div>
    <!-- STEP2 -->
    <div v-if="yeePayMerchant.step == 1">
      <el-card v-if="statusResult.applymentState">
        <div>申请状态：{{statusResult.applymentStateDesc}}</div>
        <div v-if="statusResult.applymentState == 'AGREEMENT_SIGNING'">
          请点击链接签署协议：<a :href="yeePayMerchant.agreementSignUrl" target="_blank">
            {{yeePayMerchant.agreementSignUrl}}
        </a>
        </div>
        <div v-if="statusResult.applymentState == 'REVIEW_BACK'">
          <el-table :data="statusResult.auditDetail">
            <el-table-column
              label="驳回原因："
              prop="rejectReason"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-card v-else>正在查询申请状态，请稍后...</el-card>
      <el-button @click.native.prevent="prevStep" style="margin-top: 20px"
        >上一步</el-button
      >

      <el-button
        type="primary"
        @click="progress"
        style="margin-top: 20px"
        :disabled="
          statusResult.applymentState &&
          statusResult.applymentState == 'COMPLETED'
        "
        >查询最新进度</el-button
      >

      <el-button
        @click="repeatIt"
        style="margin-top: 20px"
        :disabled="
          statusResult.applymentState &&
          statusResult.applymentState == 'COMPLETED'
        "
        >没收到短信/邮件，重发</el-button
      >

      <el-button
        type="primary"
        :loading="submitLoading"
        @click="submit"
        style="margin-top: 20px"
        :disabled="
          statusResult.applymentState &&
          statusResult.applymentState != 'COMPLETED'
        "
        >提交下一步</el-button
      >
    </div>

    <div v-if="yeePayMerchant.step == 2">
      <!-- 提交资料签约微信支付 -->

      <el-card>
        <div>审核状态：{{ wechatStatus.applymentStateDescription }}</div>
        <div v-if="wechatStatus.applymentState == 'APPLYMENT_STATE_REJECTED'">
          拒绝原因：{{ wechatStatus.rejectReason }}
        </div>
        <div v-if="wechatStatus.qrcodeUrl && wechatStatus.qrcodeUrl.length > 0" style="margin: 20px auto;width:200px;">
            <img :src="wechatStatus.qrcodeUrl" width="200" height="200"  />
        </div>
      </el-card>

      <el-button @click.native.prevent="prevStep" style="margin-top: 20px"
        :disabled="wechatStatus.authorized"
        >上一步</el-button
      >

      <el-button
        type="primary"
        @click.native.prevent="nextStep"
        style="margin-top: 20px"
        :disabled="!wechatStatus.authorized"
        >下一步</el-button
      >
    </div>

    <div v-if="yeePayMerchant.step == 3">
      <div class="bankInfo">
        <el-table :data="withdrawBanks">
          <el-table-column prop="accountName" label="账户"></el-table-column>
          <el-table-column prop="accountNo" label="卡号"></el-table-column>
          <el-table-column prop="bankName" label="银行"></el-table-column>
        </el-table>
      </div>
      <div
        style="
          width: 80%;
          font-size: 12px;
          color: red;
          margin: 20px auto 10px auto;
        "
      >
        银行卡提现T+1到账，零手续费
      </div>
    </div>

    <div class="footerBar">
      <div class="rightBtn">
        <el-button type="primary" size="medium" @click="() => $router.go(-1)"
          >返回资金中心</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Clipboard from "clipboard";
import QRCode from "qrcodejs2";

import {
  queryBankList,
  submitInformation,
  uploadBusinessPaper,
  saveInformation,
  uploadIdCardCopy,
  uploadIdCardNational,
  getInformation,
  progress,
  repeatNotify,
  progressWechat,
  queryWithdrawBank,
} from "@/api/payorder";

import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";

export default {
  components: {},
  props: ["merchant"],
  data() {
    return {
      banks: [],
      submitLoading: false,
      options: regionData,
      selectedOptions: [],
      infoFlag: true,
      interval: null,
      statusResult: {
        applymentState: "PENDING"
      },
      wechatStatus: {},
      withdrawBanks: [],
      wechatMerchantHeadFiles: [],
      wechatMerchantIndoorFiles: [],
      companyMerchantBiz: [],
      companyMerchantScreenshot: [],
      yeePayMerchant: {
        type: 1,
        wechatMerchant: {},
        microMerchant: {
          merchantSubjectInfo: {
            signName: null,
            shortName: "",
          },
          merchantCorporationInfo: {
            legalLicenceType: "ID_CARD",
            legalLicenceNo: null,
            legalLicenceFrontUrl: null,
            legalLicenceBackUrl: null,
            legalName: null,
            mobile: null,
          },
          businessAddressInfo: {
            province: null,
            city: null,
            district: null,
            address: null,
          },
          accountInfo: {
            bankAccountType: "DEBIT_CARD",
            bankCardNo: null,
            bankName: null,
            bankCode: null,
          },
        },
        companyMerchant: {
          merchantSubjectInfo: {
            signType: null,
            licenceNo: null,
            licenceUrl: null,
            signName: null,
            shortName: "",
            openAccountLicenceUrl: null,
            openAccountLicenceNo: null,
          },
          merchantCorporationInfo: {
            legalLicenceType: "ID_CARD",
            legalLicenceNo: null,
            legalLicenceFrontUrl: null,
            legalLicenceBackUrl: null,
            legalName: null,
            mobile: null,
          },
          merchantContactInfo: {
            contactName: null,
            contactMobile: null,
            contactEmail: null,
          },
          businessAddressInfo: {
            province: null,
            city: null,
            district: null,
            address: null,
          },
          settlementAccountInfo: {
            settlementDirection: "ACCOUNT",
            bankAccountType: null,
            bankCardNo: null,
            bankName: null,
            bankCode: null,
          },
        },
        step: 0,
        editable: true,
        description: null,
        requestNo: null,
        orgId: null,
        userId: null,
      },
      rules: {
        type: [
          {
            required: true,
            message: "组织类型不能为空",
            trigger: "change",
          },
        ],
      },
      companyMerchantSubjectInfo: {
        licenceUrlAction: "string",
        licenceUrlfileList: [],
        openAccountLicenceUrlAction: "string",
        openAccountLicenceUrlfileList: [],
      },
      merchantCorporationInfo: {
        legalLicenceFrontUrlAction: "string",
        legalLicenceFrontUrlfileList: [],
        legalLicenceBackUrlAction: "string",
        legalLicenceBackUrlfileList: [],
      },
      yeeMerchantCorporationInfo: {
        legalLicenceFrontUrlAction: "string",
        legalLicenceFrontUrlfileList: [],
        legalLicenceBackUrlAction: "string",
        legalLicenceBackUrlfileList: [],
      },
      typeOptions: [
        {
          label: "个人商户",
          value: 1,
        },
        {
          label: "企业/个体工商户",
          value: 2,
        },
      ],
      companyMerchantSubjectInfo: {
        signTypeOptions: [
          {
            label: "公司企业",
            value: "ENTERPRISE",
          },
          {
            label: "个体工商户",
            value: "INDIVIDUAL",
          },
          {
            label: "事业单位",
            value: "INSTITUTION",
          },
          {
            label: "社会团体",
            value: "COMMUNITY",
          },
        ],
      },
      merchantCorporationInfo: {
        legalLicenceTypeOptions: [
          {
            label: "身份证",
            value: "ID_CARD",
          },
        ],
      },
      settlementAccountInfo: {
        settlementDirectionOptions: [
          {
            label: "结算到支付账户",
            value: "ACCOUNT",
          },
        ],
        bankAccountTypeOptions: [
          {
            label: "企业对公账户",
            value: "ENTERPRISE_ACCOUNT",
          },
          {
            label: "个人借记卡",
            value: "DEBIT_CARD",
          },
          {
            label: "单位结算卡",
            value: "UNIT_SETTLEMENT_CARD",
          },
          {
            label: "存折",
            value: "PASSBOOK",
          },
        ],
      },
      yeeMerchantCorporationInfo: {
        legalLicenceTypeOptions: [
          {
            label: "身份证",
            value: "ID_CARD",
          },
        ],
      },
      accountInfo: {
        bankAccountTypeOptions: [
          {
            label: "借记卡",
            value: "DEBIT_CARD",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getBanks();
    this.getInformation();
    this.refreshProgress();
  },
  destroyed() {
    console.log("destroyed interval")
    clearInterval(this.interval)
  },
  unmounted() {
    console.log("unmounted")
    this.destroyed()
  },
  mounted() {},
  methods: {
    refreshProgress() {
      this.interval = setInterval(() => {
          this.hitStep();
      }, 10000);
    },
    queryWithdrawBank() {
      const that = this;
      queryWithdrawBank({}).then((res) => {
        if (res.code === 200) {
          that.withdrawBanks = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    createQrCode(texts) {
      this.$nextTick(function () {
        if (!document.getElementById("qrCodeId")) return;
        document.getElementById("qrCodeId").innerHTML = "";
        new QRCode("qrCodeId", {
          text: texts,
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    uploadOnChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    progress() {
      progress({}).then((res) => {
        if (res.code === 200) {
          this.statusResult = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    progressWechat() {
      progressWechat({}).then((res) => {
        if (res.code === 200) {
          this.wechatStatus = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    repeatIt() {
      repeatNotify({}).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    nextStep() {
      this.yeePayMerchant.step += 1;
      this.hitStep();
    },
    nextTowStep() {
      this.yeePayMerchant.step += 2;
      this.hitStep();
    },
    prevStep() {
      this.yeePayMerchant.step -= 1;
      this.hitStep();
    },
    hitStep() {
      if (this.yeePayMerchant.step == 1) {
        setTimeout(() => {
          this.progress();
        }, 10000);
      }
      if (this.yeePayMerchant.step == 2) {
        this.progressWechat();
      }
      if (this.yeePayMerchant.step == 3) {
        this.queryWithdrawBank();
      }
    },
    submitForm() {
      this.$refs["yeePayMerchant"].validate((valid) => {
        if (!valid) return;
        if (this.submitLoading) return;
        this.submit();
      });
    },
    submit() {
      this.submitLoading = true;
      const that = this;
      const param = {
        ...this.yeePayMerchant,
      };
      submitInformation(param)
        .then((res) => {
          that.submitLoading = false;
          if (res.code === 200) {
            if(that.yeePayMerchant.type == 1 && that.yeePayMerchant.step == 1) {
              this.nextTowStep()
            } else {
              this.nextStep();
            }
          } else {
            this.$message.error(res.message);
          }
          this.$emit("submit", that.yeePayMerchant);
        })
        .catch((err) => {
          that.submitLoading = false;
        });
    },
    resetForm() {
      this.$refs["yeePayMerchant"].resetFields();
    },
    getBanks(name) {
      const param = {
        name: name,
      };
      const that = this;
      queryBankList(param).then((res) => {
        if (res.code === 200) {
          that.banks = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getInformation() {
      const that = this;
      getInformation({}).then((res) => {
        if (res.code === 200) {
          that.info = res.data;
          that.yeePayMerchant = res.data.extend.yeePayMerchant;
          that.showUploadFiles();
          that.hitStep();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    showUploadFiles() {
      const getFileName = (fileName) => {
        if (!fileName) return fileName;
        return fileName.substring(fileName.lastIndexOf("/") + 1);
      };
      // 开户许可证
      if (this.yeePayMerchant.companyMerchant.merchantSubjectInfo.openAccountLicenceUrl) {
        this.companyMerchantSubjectInfo.openAccountLicenceUrlfileList = [
          {
            name: getFileName(this.yeePayMerchant.companyMerchant.merchantSubjectInfo.openAccountLicenceUrl),
            url: this.yeePayMerchant.companyMerchant.merchantSubjectInfo.openAccountLicenceUrl
          }
        ]
      }

      // 营业执照
      if (this.yeePayMerchant.companyMerchant.merchantSubjectInfo.licenceUrl) {
        this.companyMerchantSubjectInfo.licenceUrlfileList = [
          {
            name: getFileName(this.yeePayMerchant.companyMerchant.merchantSubjectInfo.licenceUrl),
            url: this.yeePayMerchant.companyMerchant.merchantSubjectInfo.licenceUrl
          }
        ]
      }

      if (this.yeePayMerchant.wechatMerchant.storeIndoorCopy) {
        this.wechatMerchantIndoorFiles = [
          {
            name: getFileName(
              this.yeePayMerchant.wechatMerchant.storeIndoorCopy
            ),
            url: this.yeePayMerchant.wechatMerchant.storeIndoorCopy,
          },
        ];
      }
      if (this.yeePayMerchant.wechatMerchant.storeHeaderCopy) {
        this.wechatMerchantHeadFiles = [
          {
            name: getFileName(
              this.yeePayMerchant.wechatMerchant.storeHeaderCopy
            ),
            url: this.yeePayMerchant.wechatMerchant.storeHeaderCopy,
          },
        ];
      }
      if (
        this.yeePayMerchant.companyMerchant.productQualificationInfo
          .agreementPhotoUrl
      ) {
        this.companyMerchantBiz = [
          {
            name: getFileName(
              this.yeePayMerchant.companyMerchant.productQualificationInfo
                .agreementPhotoUrl
            ),
            url: this.yeePayMerchant.companyMerchant.productQualificationInfo
              .agreementPhotoUrl,
          },
        ];
      }
      // if (
      //   this.yeePayMerchant.companyMerchant.productQualificationInfo
      //     .systemScreenshotUrl
      // ) {
      //   this.companyMerchantScreenshot = [
      //     {
      //       name: getFileName(
      //         this.yeePayMerchant.companyMerchant.productQualificationInfo
      //           .systemScreenshotUrl
      //       ),
      //       url: this.yeePayMerchant.companyMerchant.productQualificationInfo
      //         .systemScreenshotUrl,
      //     },
      //   ];
      // }
    },
    selectBank(bankCode) {
      this.yeePayMerchant.microMerchant.accountInfo.bankCode = bankCode;
    },
    saveIt() {
      const that = this;
      saveInformation(this.yeePayMerchant).then((res) => {
        if (res.code === 200) {
          let yeePayMerchant = res.data.yeePayMerchant;
          yeePayMerchant.step = 0;
          that.yeePayMerchant = yeePayMerchant;
          that.showUploadFiles();
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上传营业执照
    uploadBusinessPaper(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.companyMerchant.merchantSubjectInfo.licenceUrl =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传门头照
    uploadHead(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.wechatMerchant.storeHeaderCopy =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传业务协议
    uploadBiz(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.companyMerchant.productQualificationInfo.agreementPhotoUrl =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传系统截图
    uploadScreenshot(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.companyMerchant.productQualificationInfo.systemScreenshotUrl =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传店内照
    uploadIndoor(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.wechatMerchant.storeIndoorCopy =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传开户照片
    uploadBusinessBank(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadBusinessPaper(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.companyMerchant.merchantSubjectInfo.openAccountLicenceUrl =
              res.data.mediaId;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    uploadIdCardCopy(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadIdCardCopy(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalLicenceNo =
              res.data.number;
            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalName =
              res.data.name;
            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalLicenceFrontUrl =
              res.data.mediaId;
            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalLicenceFrontPath =
              res.data.path;

            that.yeePayMerchant.microMerchant.merchantSubjectInfo.signName =
              res.data.name;

            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalLicenceNo =
              res.data.number;
            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalName =
              res.data.name;
            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalLicenceFrontUrl =
              res.data.mediaId;
            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalLicenceFrontPath =
              res.data.path;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    // 上传身份证反面
    uploadIdCardNational(param) {
      var that = this;
      const formData = new FormData();
      formData.append("file", param.file);
      uploadIdCardNational(formData)
        .then((res) => {
          if (res.code == 200) {
            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalLicenceBackUrl =
              res.data.mediaId;
            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalLicenceBackUrl =
              res.data.mediaId;

            that.yeePayMerchant.wechatMerchant.identificationValidDateStart = res.data.startDate
            that.yeePayMerchant.wechatMerchant.identificationValidDateEnd = res.data.endDate

            that.yeePayMerchant.microMerchant.merchantCorporationInfo.legalLicenceBackPath =
              res.data.path;
            that.yeePayMerchant.companyMerchant.merchantCorporationInfo.legalLicenceBackPath =
              res.data.path;
          } else {
            that.$message.error(res.message);
          }
        })
        .catch((response) => {
          console.log("图片上传失败");
          param.onError();
        });
    },
    handleChangeCity(value) {
      console.log("city value", value);
      this.yeePayMerchant.yeeCity.province = CodeToText[value[0]];
      this.yeePayMerchant.yeeCity.city = CodeToText[value[1]];
      this.yeePayMerchant.yeeCity.district = CodeToText[value[2]];

      this.yeePayMerchant.microMerchant.businessAddressInfo.province = value[0];
      this.yeePayMerchant.microMerchant.businessAddressInfo.city = value[1];
      this.yeePayMerchant.microMerchant.businessAddressInfo.district = value[2];

      this.yeePayMerchant.companyMerchant.businessAddressInfo.province =
        value[0];
      this.yeePayMerchant.companyMerchant.businessAddressInfo.city = value[1];
      this.yeePayMerchant.companyMerchant.businessAddressInfo.district =
        value[2];
    },
    companyMerchantSubjectInfoLicenceUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    companyMerchantSubjectInfoOpenAccountLicenceUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    merchantCorporationInfoLegalLicenceFrontUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    merchantCorporationInfoLegalLicenceBackUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    yeemerchantCorporationInfoLegalLicenceFrontUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    yeemerchantCorporationInfoLegalLicenceBackUrlBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2;
      if (!isRightSize) {
        this.$message.error("文件大小超过 2MB");
      }
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择image/*类型的文件");
      }
      return isRightSize && isAccept;
    },
    layerClose() {
      this.infoFlag = false;
    },
  },
};
</script>
<style scoped>
.el-form-item__label {
  font-size: 12px;
}
.main {
  padding: 20px 50px 120px 50px;
  margin: 10px;
  background-color: white;
}
.el-button--primary {
  color: #fff;
  background-color: #07c160;
  border-color: #07c160;
}
.el-upload__tip {
  line-height: 1.2;
}
.tips {
  font-size: 12px;
  color: red;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 130px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 130px;
  display: block;
}
.bankInfo{
  width:80%;
  margin: 0 auto;
}
.note {
  color:red;
  font-size:12px;
  margin:0px 0px 40px 10px;
}
</style>
